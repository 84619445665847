<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        Attendance Devices
                        <v-spacer />
                        <v-btn
                            v-if="$auth.getRole() === 'administrator'"
                            :loading="form.loading"
                            :disabled="loading"
                            color="primary"
                            text
                            outlined
                            small
                            @click="syncDevices()"
                        >
                            <!-- <i class="fa fa-refresh" aria-hidden="true"></i>   -->
                            Sync Devices
                        </v-btn>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="form.items.data" :options.sync="pagination" :footer-props="footerProps"
                        :server-items-length="form.items.meta.total" :loading="form.loading">
                        <template v-slot:item="{ index, item }">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                  {{ item.name }}
                                  <!-- <v-chip small label outlined
                                      :color="item.group_type === 'positive' ? 'success' : 'error'">{{ item.group_type }}</v-chip> -->

                                  <!-- <div v-if="item.description">
                                      <span>
                                      <view-more-or-less :paragraph="item.description" limit="70"></view-more-or-less>
                                      </span>
                                  </div> -->
                                </td>
                                <td class="text-xs-left">
                                    {{ item.device_index }}
                                </td>
                                <td class="text-xs-left">
                                    {{ item.model }}
                                </td>
                                <td class="text-xs-left status-cell">
                                  <span
                                    class="status-dot"
                                    :class="item.status === 'active' ? 'dot-active' : 'dot-inactive'"
                                  ></span>

                                  {{ item.status === 'active' ? 'Online' : 'Offline' }}
                                </td>
                               
                             
                            </tr>
                        </template>
                    </v-data-table>
                    
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    form: new Form(
      {
        name: '',
        dev_index: '',
        status:''
      },
      "/api/attendance-devices"
    ),
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Dev Index", align: "left", value: "device_index", sortable: false },
      { text: "Model", align: "left", value: "model", sortable: false },
      { text: "Status", align: "left", value: "status", sortable: false },
    //   { text: "Action", align: "right", sortable: false },
    ],
   
  }),

  mounted() {
    // this.getGrades();
  },
  watch: {
    pagination: function () {
      this.get();
    },
  },
  methods: {
    syncDevices(){
        this.loading = true
        this.form.store().finally((f) => {
          setTimeout(() => {
            this.loading = false;
          }, 10000);
        })
    },
    get(){
        this.form.get().then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    }
  },
};
</script>

<style>
  .status-cell {
  display: flex;
  align-items: center;
  gap: 6px; /* space between dot and text */
}

.status-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
}

.dot-active {
  background-color: green;
}

.dot-inactive {
  background-color: red;
}

</style>